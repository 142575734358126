<template lang="pug">

.q-px-xl(style="padding-top:80px;padding-bottom:80px;")
  //- description
  .flex.flex-center.column
    .q-px-xl.flex.flex-center.column(style="max-width:600px;")
      .row.text-h4.text-bold
        q-icon(:name="$icon.fasMousePointer")
        |&nbsp;&nbsp;Powerful Actions
      .q-ma-md.text-subtitle1.text-center
        | Choose actions to automate for operators for any given circumstances in one click.
    //- pills
    .q-pa-lg.row.flex-center(style="gap:8px;")
      q-btn(
        label="Play Audio"
        unelevated rounded no-caps
        :icon="$icon.ionMegaphone"
        :ripple="false"
        :text-color="selected === '1' ? 'white' : 'grey-6'"
        :color="selected === '1' ? 'black' : null"
        @click="selected='1'")
      q-btn(
        label="Control Relay"
        unelevated rounded no-caps
        icon="videogame_asset"
        :ripple="false"
        :text-color="selected === '2' ? 'white' : 'grey-6'"
        :color="selected === '2' ? 'black' : null"
        @click="selected='2'")
      q-btn(
        label="Send Verification"
        unelevated rounded no-caps
        icon="people"
        :ripple="false"
        :text-color="selected === '3' ? 'white' : 'grey-6'"
        :color="selected === '3' ? 'black' : null"
        @click="selected='3'")
      q-btn(
        label="Send Email"
        unelevated rounded no-caps
        icon="mail"
        :ripple="false"
        :text-color="selected === '4' ? 'white' : 'grey-6'"
        :color="selected === '4' ? 'black' : null"
        @click="selected='4'")
        
  //- preview
  .q-px-xl.q-mt-lg.flex.flex-center
    .bg-white.rounded-borders.shadow-10(style="width:700px;height:400px;")

</template>

<style>
</style>

<script>
export default {
  data: function () {
    return {
      selected: "1",
    }
  },
}
</script>
