<template lang="pug">

.q-px-xl(style="padding-top:80px;padding-bottom:80px;")
  //- description
  .flex.flex-center.column
    .q-px-xl.flex.flex-center.column(style="max-width:600px;")
      .row.text-h4.text-bold
        q-icon(name="auto_awesome_motion")
        |&nbsp;&nbsp;Threat Levels
      .q-ma-md.text-subtitle1.text-center
        | Choose from over 100 action plans that gives a clear instruction to operators for any given circumstances in one click.
    //- pills
    .q-pa-lg.row.flex-center(style="gap:8px;")
      q-icon(name="ion-ios-add")  
      q-btn(
        label="No Human Activity"
        unelevated rounded no-caps
        icon="looks_one"
        :ripple="false"
        :text-color="selected === 'tl1' ? 'white' : 'grey-6'"
        :color="selected === 'tl1' ? 'black' : null"
        @click="selected='tl1'")
      q-btn(
        label="Human or Vehicle Activity"
        unelevated rounded no-caps
        icon="looks_two"
        :ripple="false"
        :text-color="selected === 'tl2' ? 'white' : 'grey-6'"
        :color="selected === 'tl2' ? 'black' : null"
        @click="selected='tl2'")
      q-btn(
        label="Clear Criminal Intent"
        unelevated rounded no-caps
        icon="looks_3"
        :ripple="false"
        :text-color="selected === 'tl3' ? 'white' : 'grey-6'"
        :color="selected === 'tl3' ? 'black' : null"
        @click="selected='tl3'")
  //- preview
  .q-px-xl.q-mt-lg.flex.flex-center
    .bg-white.rounded-borders.shadow-10(style="width:700px;height:400px;")

</template>

<style>
</style>

<script>
export default {
  data: function () {
    return {
      selected: "tl1",
    }
  },
}
</script>
