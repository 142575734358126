<template lang="pug">

q-layout(view="hhh LpR fff")
  //- Layout
  Header
  Footer

  //- Body
  q-page-container
    q-page
      section(id="hero")
        Hero
      section(id="features")
        VideoAnalytics.bg-grey-9
      section(id="actions")
        ThreatLevels.bg-white
      section(id="community")
        Actions
      //- section(id="pricing")
      //-   Placeholder.bg-blue
      //- section(id="tweets")
      //-   Placeholder

</template>

<style>
</style>

<script>
import Header from "./_comps/Header.vue";
import Footer from "./_comps/Footer.vue";
import Hero from "./_comps/Hero.vue"
import Placeholder from "./_comps/Placeholder.vue"
import ThreatLevels from "./_comps/ThreatLevels.vue"
import VideoAnalytics from "./_comps/VideoAnalytics.vue"
import Actions from "./_comps/Actions.vue"

export default {
  components: {
    Header,
    Footer,
    Hero,
    Placeholder,
    ThreatLevels,
    VideoAnalytics,
    Actions,
  },
}
</script>
