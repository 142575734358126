<template lang="pug">

.q-px-xl.text-white(style="padding-top:80px;padding-bottom:80px;")
  //- description
  .flex.flex-center.column
    .q-px-xl.flex.flex-center.column(style="max-width:600px;")
      .row.text-h4.text-bold
        q-icon(name="auto_fix_high")
        |&nbsp;&nbsp;Analyze Video
      .q-ma-md.text-subtitle1.text-center
        | Add video analytics into your automations that eases mission critical tasks for your operators in one click.
    //- pills
    .q-pa-lg.row.flex-center(style="gap:8px;")
      q-btn(
        label="CHeKT DLF"
        unelevated rounded no-caps
        icon="psychology"
        :ripple="false"
        :text-color="selected === 'chekt' ? 'black' : 'grey-6'"
        :color="selected === 'chekt' ? 'white' : null"
        @click="selected='chekt'")
      q-btn(
        label="GCP Video AI"
        unelevated rounded no-caps
        :icon="$icon.ionLogoGoogle"
        :ripple="false"
        :text-color="selected === 'gcp' ? 'black' : 'grey-6'"
        :color="selected === 'gcp' ? 'white' : null"
        @click="selected='gcp'")
      q-btn(
        label="Azure Video Analyzer"
        unelevated rounded no-caps
        :icon="$icon.ionLogoMicrosoft"
        :ripple="false"
        :text-color="selected === 'azure' ? 'black' : 'grey-6'"
        :color="selected === 'azure' ? 'white' : null"
        @click="selected='azure'")
  //- preview
  .q-px-xl.q-mt-lg.flex.flex-center
    .bg-white.rounded-borders.shadow-10(style="width:700px;height:400px;")

</template>

<style>
</style>

<script>
export default {
  data: function () {
    return {
      selected: "chekt",
    }
  },
}
</script>
