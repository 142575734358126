<template lang="pug">

q-header.bg-grey-2.flex.flex-center
  q-toolbar.q-pa-xl.bg-grey-2.text-grey-8(style="max-width:1000px;")
    .text-subtitle1
      span.text-black Automator
      span.text-grey-5 &nbsp;for central stations
    q-space
    q-btn(flat no-caps :ripple="false") Features
    q-btn(flat no-caps :ripple="false") Video Analytics
    q-btn(flat no-caps :ripple="false") Actions
    q-btn(flat no-caps :ripple="false") Pricing

</template>

<style>
</style>

<script>
export default {
}
</script>
