<template lang="pug">

.q-px-xl(style="padding-top:80px;padding-bottom:80px;")
  //- description
  .q-px-xl.flex.flex-center.column
    .text-h4.text-bold
      | There’s an automation for that
    .text-subtitle1.q-ma-md
      | Automate your Monitoring tasks in one click.
  //- preview
  .q-px-xl.flex.flex-center
    q-skeleton(width="800px" height="500px")
  //- credits
  .q-pa-lg.full-width.text-center
    | Made by a central station
    span &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    //- | Works with your monitoring
    | Works with central stations

</template>

<style>
</style>

<script>
export default {
}
</script>
