<template lang="pug">

q-footer.bg-grey-2.flex.flex-center
  q-toolbar.q-pa-xl.bg-grey-2.text-grey-8(style="max-width:1000px;")
    .text-subtitle1
      span.text-black Automator
    q-space
    q-btn(flat no-caps :ripple="false") Help
    q-btn(flat no-caps :ripple="false") Contact

</template>

<style>
</style>

<script>
export default {
}
</script>
